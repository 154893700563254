import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBtc} from "@fortawesome/free-brands-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import '../../assets/css/index.css';

class App extends Component {
    render() {
        return (
            <div className="d-flex flex-column h-100">
                <div className="bg-bitcoin content flex-grow-1">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="white display-1">
                            <FontAwesomeIcon icon={faBtc}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
